import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homepage from "./pages/Homepage";
import PrivacyPolicy from "./common/PrivacyPolicy"; 
import Footer from "./common/Footer";
import AllRightReserved from "./common/AllRightReserved";
import UseTerms from "./common/UseTerms";
import Header from "./common/Header";
import Certification from "./common/Certification";
import Leadership from "./common/leadership-team";  
import Vision from "./common/vision"; 
import Mission from "./common/mission";  
import Journey from "./common/journey";  

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" exact element={<Homepage />} />
        {/* <Route path="/Certification" exact element={<Certification />} /> */}
        <Route path="/leadership-team" exact element={<Leadership />} />
        <Route path="/vision" exact element={<Vision />} />
        <Route path="/mission" exact element={<Mission />} />
        <Route path="/journey" exact element={<Journey />} /> {/* Make sure "Journey.jsx" matches */}
        <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" exact element={<UseTerms />} />
        <Route path="/compliance-security-quality" exact element={<Certification />} />
      </Routes>
      <Footer />
      <AllRightReserved />
    </BrowserRouter>
  );
};

export default AppRoutes;
