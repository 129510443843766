import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import PrimaryButton from "./PrimaryButton";
import ContactUs from "./ContactUs";

const appUrl = process.env.REACT_APP_NEW_BASE_URL || "";

const OurVision = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  function handleopenContactUsModal() {
    setIsOpen(true);
  }

  function handlecloseContactUsModal() {
    setIsOpen(false);
  }

  const baseUrl = process.env.REACT_APP_NEW_BASE_URL;

  return (
    <>
      <Helmet>
        <title>Our Company Vision | Actionabl</title>
        <meta
          name="description"
          content="Our vision goes beyond automation—it's about transforming how businesses operate, connect, and grow in an ever-evolving world."
        />
        <meta property="og:title" content="Our Company Vision | Actionabl" />
        <meta
          property="og:description"
          content="Our vision goes beyond automation—it's about transforming how businesses operate, connect, and grow in an ever-evolving world."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${baseUrl}/our-vision`} />
        <meta
          property="og:image"
          content={`${baseUrl}/og-img/OurVisionog.jpg`}
        />
        <meta
          name="twitter:image"
          content={`${baseUrl}/og-img/OurVisionog.jpg`}
        />
        <meta name="twitter:title" content="Our Company Vision | Actionabl" />
        <meta
          name="twitter:description"
          content="Our vision goes beyond automation—it's about transforming how businesses operate, connect, and grow in an ever-evolving world."
        />
      </Helmet>

      <div className="relative mb-24 bg-gradient-to-br bg-[#f1f5f9] overflow-hidden">
        {/* Header Section */}
        <div className="relative w-full h-48 sm:h-64 md:h-80 lg:h-96 xl:h-[400px] mb-8 bg-cover bg-center hero-bg flex justify-center items-center">
          <div className="shadow-lg rounded-lg p-8 relative z-10 text-center">
            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-bold text-white mb-6">
              Actionabl Vision
            </h1>

            <PrimaryButton
              onClick={handleopenContactUsModal}
              className={"max-w-[164px] w-full mx-auto"}
            >
              Get Started
            </PrimaryButton>
          </div>
        </div>

        <div className="mt-4 pl-8 relative z-10">
          <Link
            to="/"
            className="text-blue-500 hover:underline font-medium transition-colors"
          >
            Home
          </Link>
          <span className="text-gray-600 mx-2"> &gt; </span>
          <span className="text-gray-800">Actionabl Vision</span>
        </div>

        <section className="pt-[20px]">
          <div className="container mx-auto py-8 sm:py-12 md:py-14 lg:py-16 px-4 sm:px-6 md:px-8 lg:px-10 bg-white bg-opacity-50 mt-10">
            <p className="mt-2 text-lg text-deep-night">
              At Actionabl, we are driven by a bold and forward-thinking
              vision—creating the Autonomous Enterprise. This vision is not
              merely about automation; it’s about transforming the way
              businesses operate, interact, and scale in a rapidly changing
              world. Our mission is to empower organizations to achieve full
              autonomy by transforming their processes into cohesive, resilient,
              safe, agile, and transparent systems that can swiftly adapt to
              evolving business needs.
            </p>
          </div>

          <div className="grid grid-cols-1 gap-6 mt-10 px-4 pb-20">
            {[
              {
                title: "Creating Cohesive Processes",
                content:
                  "Cohesive processes are the backbone of an organization’s success. At Actionabl, we believe that interconnected processes are the key to unlocking operational efficiency. Our vision extends beyond mere task automation to ensuring that all parts of a business work together seamlessly. By integrating various functions such as finance, HR, supply chain, and customer service into a unified, cohesive system, we eliminate silos and foster collaboration.",
                iconPath: "/icons/Creating-Cohesive-Processes.jpg",
              },
              {
                title: "Building Resilience",
                content:
                  "Actionabl’s vision emphasizes the need for resilient processes that can withstand challenges and recover swiftly from disruptions. We design our platform to provide businesses with the agility to adapt to sudden market shifts, technological advances, and unforeseen challenges. Our platform enables organizations to anticipate risks, mitigate them proactively, and continue operations without significant setbacks.",
                iconPath: "/icons/Building-Resilience.jpg",
              },
              {
                title: "Ensuring Safety and Security",
                content:
                  "Our vision at Actionabl is to embed security at every level of process automation. We prioritize data integrity, privacy, and security, ensuring that our Actionabl platform protects sensitive information and meets the highest standards of compliance, which build trust that strengthens overall business operational resilience.",
                iconPath: "/icons/Safety-and-Security.jpg",
              },
              {
                title: "Promoting Agility",
                content:
                  "Actionabl’s vision involves equipping organizations with the platform they need to be agile, enabling them to pivot quickly in response to market demands. Our platform is designed to be flexible, allowing businesses to modify processes on the fly, integrate new technologies effortlessly, and scale operations as needed.",
                iconPath: "/icons/Promoting-Agility.jpg",
              },
              {
                title: "Fostering Transparency",
                content:
                  "Our vision includes creating processes that are open and transparent, allowing organizations to understand and manage their operations in real-time. Our Actionabl platform provides comprehensive insights into process performance, making it easier for business leaders to monitor, analyze, and optimize operations.",
                iconPath: "/icons/Fostering-Transparency.jpg",
              },
            ].map(({ title, content, iconPath }, index) => (
              <div
                key={index}
                className="flex flex-col lg:flex-row items-center lg:items-start shadow-lg rounded-xl bg-white mx-4 p-6"
              >
                <img
                  src={iconPath}
                  alt={title}
                  className="flex flex-col items-center lg:items-start mb-4 lg:mb-0 lg:mr-4"
                />
                <div className="text-center lg:text-left">
                  <h3 className="2xl:text-[32px] 1xl:text-3xl xl:text-2xl lg:text-xl text-lg font-bold text-secondary text-gray-900 mb-2">
                    {title}
                  </h3>
                  <p className="font-normal 3xl:max-w-[376px] mt-2 text-gray-600">
                    {content}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </section>

        <ContactUs
          isOpen={isOpen}
          handlecloseContactUsModal={handlecloseContactUsModal}
        />
      </div>
    </>
  );
};

export default OurVision;
