import { useState } from "react";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import PrimaryButton from "./PrimaryButton";

export default function ContactUs({ isOpen, handlecloseContactUsModal }) {
  const [formData, setFormData] = useState({
    FullName: "",
    Email: "",
    PhoneNumber: "",
    Company: "",
    Designation: "",
    Country: "",
    Message: "",
    Consent: false, // Add consent field
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateForm = () => {
    let formIsValid = true;
    let newErrors = {};

    if (
      !formData.FullName.trim() ||
      formData.FullName.trim().split(" ").length < 2
    ) {
      formIsValid = false;
      newErrors.FullName =
        "Please enter at least two names (e.g., First and Last name).";
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.Email.trim()) {
      formIsValid = false;
      newErrors.Email = "Email is required.";
    } else if (!emailRegex.test(formData.Email)) {
      formIsValid = false;
      newErrors.Email = "Please enter a valid email address.";
    }

    if (!/^\d{10}$/.test(formData.PhoneNumber)) {
      formIsValid = false;
      newErrors.PhoneNumber = "Please enter a valid 10-digit phone number.";
    }

    if (!formData.Company.trim()) {
      formIsValid = false;
      newErrors.Company = "Company name is required.";
    }

    if (!formData.Designation.trim()) {
      formIsValid = false;
      newErrors.Designation = "Designation is required.";
    }

    if (!formData.Country.trim()) {
      formIsValid = false;
      newErrors.Country = "Please select your country.";
    }

    if (!formData.Consent) {
      formIsValid = false;
      newErrors.Consent = "Please check the above box to proceed.";
    }

    setErrors(newErrors);
    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);

    const sendMail = process.env.REACT_APP_NEXT_PUBLIC_SEND_EMAIL_API;

    const payload = {
      FullName: formData.FullName,
      Company: formData.Company,
      Email: formData.Email,
      PhoneNumber: formData.PhoneNumber,
      Designation: formData.Designation,
      Country: formData.Country,
      Message: formData.Message,
      Consent: formData.Consent,
    };

    try {
      const response = await fetch(sendMail, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      alert(
        "Thank you! We've received your message and will respond within 24 hours."
      );
      handlecloseContactUsModal();

      setFormData({
        FullName: "",
        Email: "",
        PhoneNumber: "",
        Company: "",
        Designation: "",
        Country: "",
        Message: "",
        Consent: false,
      });
    } catch (error) {
      console.error("There was an error submitting the form:", error);
      alert("Failed to submit the form.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={isOpen}
      as="div"
      className="relative z-50 focus:outline-none"
      onClose={handlecloseContactUsModal}
    >
      <DialogBackdrop className="fixed inset-0 bg-black/30 backdrop-blur-xl" />
      <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-3 sm:p-4">
          <DialogPanel className="w-full max-w-[100%] sm:max-w-[600px] rounded-[32px] bg-white p-5 sm:p-8 xl:p-12 duration-300 ease-out">
            <h4 className="text-center text-2xl sm:text-3xl lg:text-4xl font-bold leading-[120%] text-black mb-6">
              Contact Us
            </h4>

            <form onSubmit={handleSubmit}>
              <div className="flex flex-col sm:flex-row items-center justify-between sm:space-x-4 xl:space-x-6 mb-4">
                <div className="w-full mb-2">
                  <label
                    htmlFor="FullName"
                    className="text-lg font-normal text-[#1D2939]"
                  >
                    Full Name <span className="text-red-600">*</span>
                  </label>
                  <input
                    required
                    name="FullName"
                    type="text"
                    placeholder="Enter your full name"
                    value={formData.FullName}
                    onChange={handleChange}
                    className="border border-[#1018281F] rounded-2xl shadow bg-white placeholder:text-[#667085] text-lg h-14 w-full px-4 py-2 mt-2"
                  />
                  {errors.FullName && (
                    <p className="text-red-600 mt-1 text-sm">
                      {errors.FullName}
                    </p>
                  )}
                </div>
                <div className="w-full">
                  <label
                    htmlFor="Email"
                    className="text-lg font-normal text-[#1D2939]"
                  >
                    Email <span className="text-red-600">*</span>
                  </label>
                  <input
                    required
                    name="Email"
                    type="email"
                    placeholder="Enter your email"
                    value={formData.Email}
                    onChange={handleChange}
                    className="border border-[#1018281F] rounded-2xl shadow bg-white placeholder:text-[#667085] text-lg h-14 w-full px-4 py-2 mt-2"
                  />
                  {errors.Email && (
                    <p className="text-red-600 mt-1 text-sm">{errors.Email}</p>
                  )}
                </div>
              </div>

              <div className="flex flex-col sm:flex-row items-center justify-between sm:space-x-4 xl:space-x-6 mb-4">
                <div className="w-full mb-2">
                  <label
                    htmlFor="PhoneNumber"
                    className="text-lg font-normal text-[#1D2939]"
                  >
                    Phone Number <span className="text-red-600">*</span>
                  </label>
                  <input
                    required
                    name="PhoneNumber"
                    type="tel"
                    placeholder="Enter your phone number"
                    value={formData.PhoneNumber}
                    onChange={handleChange}
                    pattern="\d{10}"
                    minLength="10"
                    maxLength="10"
                    className="border border-[#1018281F] rounded-2xl shadow bg-white placeholder:text-[#667085] text-lg h-14 w-full px-4 py-2 mt-2"
                  />
                  {errors.PhoneNumber && (
                    <p className="text-red-600 mt-1 text-sm">
                      {errors.PhoneNumber}
                    </p>
                  )}
                </div>
                <div className="w-full">
                  <label
                    htmlFor="Company"
                    className="text-lg font-normal text-[#1D2939]"
                  >
                    Company <span className="text-red-600">*</span>
                  </label>
                  <input
                    required
                    name="Company"
                    type="text"
                    placeholder="Enter your company"
                    value={formData.Company}
                    onChange={handleChange}
                    className="border border-[#1018281F] rounded-2xl shadow bg-white placeholder:text-[#667085] text-lg h-14 w-full px-4 py-2 mt-2"
                  />
                  {errors.Company && (
                    <p className="text-red-600 mt-1 text-sm">
                      {errors.Company}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex flex-col sm:flex-row items-center justify-between sm:space-x-4 xl:space-x-6 mb-4">
                <div className="w-full mb-2">
                  <label
                    htmlFor="Designation"
                    className="text-lg font-normal text-[#1D2939]"
                  >
                    Designation <span className="text-red-600">*</span>
                  </label>
                  <input
                    required
                    name="Designation"
                    type="text"
                    placeholder="Enter your designation"
                    value={formData.Designation}
                    onChange={handleChange}
                    className="border border-[#1018281F] rounded-2xl shadow bg-white placeholder:text-[#667085] text-lg h-14 w-full px-4 py-2 mt-2"
                  />
                  {errors.Designation && (
                    <p className="text-red-600 mt-1 text-sm">
                      {errors.Designation}
                    </p>
                  )}
                </div>
                <div className="w-full">
                  <label
                    htmlFor="Country"
                    className="text-lg font-normal text-[#1D2939]"
                  >
                    Country <span className="text-red-600">*</span>
                  </label>
                  <input
                    required
                    name="Country"
                    type="text"
                    placeholder="Enter your country"
                    value={formData.Country}
                    onChange={handleChange}
                    className="border border-[#1018281F] rounded-2xl shadow bg-white placeholder:text-[#667085] text-lg h-14 w-full px-4 py-2 mt-2"
                  />
                  {errors.Country && (
                    <p className="text-red-600 mt-1 text-sm">
                      {errors.Country}
                    </p>
                  )}
                </div>
              </div>

              <div className="mb-4">
                <label
                  htmlFor="Message"
                  className="text-lg font-normal text-[#1D2939]"
                >
                  Message
                </label>
                <textarea
                  name="Message"
                  rows="4"
                  placeholder="Enter your message"
                  value={formData.Message}
                  onChange={handleChange}
                  className="border border-[#1018281F] rounded-2xl shadow bg-white placeholder:text-[#667085] text-lg w-full px-4 py-2 mt-2"
                ></textarea>
              </div>
              <div className="form-check mb-4 ">
                <input
                  className="form-check-input mr-2 text-black "
                  type="checkbox"
                  name="Consent"  
                  checked={formData.Consent}
                  onChange={handleChange}
                  id="flexCheckDefault"
                />
                <label
                  className="form-check-label text-lg text-[#1D2939]"
                  for="flexCheckDefault"
                >
                  I consent to the collection and use of my personal data as
                  outlined in the{" "}
                  <a
                    href="/privacy-policy"
                    className="text-[#7B5BF0] hover:underline font-bold"
                  >
                    Privacy Policy
                  </a>
                  .
                </label>
                {errors.Consent && (
                  <p className="text-red-600 mt-1 text-sm">{errors.Consent}</p>
                )}
              </div>
              <div className="flex justify-center space-x-4">
                <PrimaryButton
                  type="submit"
                  className="w-full sm:w-auto bg-[#7B5BF0] hover:bg-[#6349cc] text-white text-lg px-6 py-3 rounded-2xl"
                  loading={loading}
                >
                  Submit
                </PrimaryButton>
                <PrimaryButton
                  onClick={handlecloseContactUsModal}
                  className="w-full sm:w-auto bg-[#7B5BF0] hover:bg-[#6349cc] text-white text-lg px-6 py-3 rounded-2xl"
                >
                  Cancel
                </PrimaryButton>
              </div>
            </form>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}