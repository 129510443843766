const CoreCapabilitiesCard = ({ benefitsItem }) => {
  if (!benefitsItem) {
    return null;
  }

  return (
    <div className="flex flex-wrap justify-center gap-6 custom-gap xl:pb-20 md:pb-16 pb-14">
      <article className="bg-white shadow-[0_20px_50px_rgba(8,_112,_184,_0.3)] hover:shadow-[0_20px_50px_rgba(8,_112,_184,_0.5)] transition-shadow duration-300 lg:px-10 px-6 py-8 flex items-center justify-center flex-col rounded-[28px] cursor-pointer border border-gray-200 w-full sm:w-[300px] md:w-[350px] max-w-[90%] overflow-hidden transform hover:scale-105 transition-transform duration-300">
        <span className="max-w-[80px] max-h-[80px] w-full h-full rounded-full flex items-center justify-center bg-blue-50 p-4 transition-colors duration-300 hover:bg-blue-100">
          {benefitsItem.icon}
        </span>
        <p className="text-[16px] lg:text-2xl font-bold text-gray-900 mt-6 text-center max-w-[600px]">
          {benefitsItem.descriptions}
        </p>
        <h4 className="text-[0.75rem] lg:text-[1.125rem] font-light text-gray-600 mt-2 text-center max-w-[600px]">
          {benefitsItem.title}
        </h4>
      </article>
    </div>
  );
};

export default CoreCapabilitiesCard;