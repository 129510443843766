import {
  AgilityIcon,
  AugmentationIcon,
  AutonomousIcon,
  CodeAppsIcon,
  ControlIcon,
  EnhancedAgilityIcon,
  ImprovedEfficiencyIcon,
  InnovationPartnerIcon,
  LinkedinIcon,
  OpenArchitectureIcon,
  OpenExtendableIcon,
  OpticalCharacterIcon,
  ProcessAnalyticsIcon,
  ReducedCostsIcon,
  ResiliencyIcon,
  RiskManagementIcon,
  RoboticProcessIcon,
  SafetyIcon,
  YouTubeIcon,
  TraceabilityIcon,
  TransformationManagerIcon,
  TwitterIcon,
  UserCentricIcon,
  VisibilityIcon,
} from "./Icons";

export const navItems = [
  // { to: "#", label: "Home" },
  // { to: "#", label: "About us" },
  // { to: "#", label: "Service" },
  // { to: "#", label: "Blog" },
];

export const footerItems = {
  company: [
    // { name: "About us", href: "#" },
    // { name: "Services", href: "#" },
    // { name: "Blog", href: "#" },
  ],
  links: [
    { name: "Investor Information", href: "#" },
    { name: "Terms of Service", href: "#" },
    { name: "Privacy Policy", href: "#" },
    { name: "Documentation", href: "#" },
  ],
  socialLinks: [

    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/company/actionabl",
      icon: <LinkedinIcon />,
    },


    { name: "Twitter", href: "https://x.com/ActionablAi", icon: <TwitterIcon /> },
    
    {
      name: "YouTube",
      href: "https://www.youtube.com/@Actionabl",
      icon: <YouTubeIcon />,
    },
  ],
};

export const capabilities = [
  {
    id: 6,
    icon: <TransformationManagerIcon />,
    descriptions: "Business Orchestration",
    title: "An integrated management approach that seamlessly connects workflows, technology, and people to create end-to-end automated processes."
  },
 {
    id: 4,
    icon: <AugmentationIcon />,
    descriptions:  "AI Augmentation",
    title:"Integration of Artificial Intelligence (AI) technologies that create a symbiotic relationship to enhance human capabilities and decision-making processes.",
  },
    {
      
    id: 1,
    icon: <RoboticProcessIcon/>,
    descriptions: "Agentic Process Automation",
    title:"AI-Powered Robotic Process Automation (RPA), and Optical Character Recognition (OCR) agents to run the worflows."
    },
  {
    id: 31,
    icon: <CodeAppsIcon  />,
    descriptions: "No-Code Apps and UI Designer",
    title:"Intuitive visual interfaces, simple drag-and-drop elements, and pre-built templates simplify the development process, ensuring it's easy for everyone to use.",
  },
  {
    id: 5,
      icon: <ProcessAnalyticsIcon />,
      descriptions: (
        <>
          Business<br />Analytics
        </>
      ),
      title: "Using data-driven, actionable insights and advanced analytics to make informed decisions, optimize operations, and predict future trends effectively."

  },
//  {
//     id: 2,
//     icon: <OpticalCharacterIcon />,
//     descriptions: "Optical Character Recognition (OCR)",
//   },
 {
  },
  // {
  //   id: 6,
  //   icon: <TransformationManagerIcon />,
  //   descriptions: "Transformation Manager",
  // },
];
export const enterpriseCard = [
  {
    id: 1,
    icon: <VisibilityIcon />,
    title: "Visibility",
    descriptions:
      "Full visibility into the end-to-end process, providing clear insights into workflows, which are essential for monitoring and decision-making.",
  },
  {
    id: 2,
    icon: <TraceabilityIcon />,
    title: "Traceability",
    descriptions:
      "Detailed records of all actions and data within the process ensure full accountability and complete auditability.",
  },
  {
    id: 3,
    icon: <ResiliencyIcon />,
    title: "Resiliency",
    descriptions:
      "Dynamic user interfaces and user intervention entry points allow for prompt error resolution and manual task completion when automation falters.",
  },
  {
    id: 4,
    icon: <AgilityIcon />,
    title: "Agility",
    descriptions:
      "Intuitive rules and policy interfaces enable users to respond immediately and adapt to changing business needs.",
  },
  {
    id: 5,
    icon: <SafetyIcon />,
    title: "Safety",
    descriptions:
      "Dynamic checkpoints enable users to control gates based on threat assessment, safeguarding the system against potential disruptions.",
  },
  {
    id: 6,
    icon: <ControlIcon />,
    title: "Control",
    descriptions:
      "Complete user control over the entire process to pause or halt the process at any step, minimizing errors and avoiding rework.",
  },
];
export const keyBenefits = [
  {
    id: 4,
    icon: <RiskManagementIcon />,
    title: "Risk Management and Resilience",
    descriptions:
      "With audit and QC checks, comprehensive error recovery, and shared human-machine workspaces, Actionabl ensures business continuity and robust risk management.",
  },
  {
    id: 2,
    icon: <EnhancedAgilityIcon />,
    title: "Enhanced Agility",
    descriptions:
      "Quickly adapt to changing business needs with Actionabl's externalized decision-structures and business rules. Stay competitive by quickly incorporating new technologies into your processes.",
  },
  {
    id: 5,
    icon: <OpenArchitectureIcon />,
    title: "Open Architecture",
    descriptions:
      "Swiftly and easily integrate 3rd party tools and open-source frameworks with Actionabl's open architecture.",
  },
  {
    id: 3,
    icon: <ImprovedEfficiencyIcon />,
    title: "Improved Efficiency",
    descriptions:
      "With Actionabl's user intervention and error recovery systems and its central control and monitoring, you can achieve high-level efficiency, business continuity, and increased automatability.",
  },
  {
    id: 1,
    icon: <ReducedCostsIcon />,
    title: "Reduced Costs",
    descriptions:
      "Our no-code and low-code modules significantly reduce development effort, saving valuable time and money and reducing 3rd party license costs.",
  }
  // {
  //   id: 4,
  //   icon: <RiskManagementIcon />,
  //   title: "Risk Management and Resilience",
  //   descriptions:
  //     "With audit and QC checks, comprehensive error recovery, and shared human-machine workspaces, Actionabl ensures business continuity and robust risk management.",
  // },
  // {
  //   id: 5,
  //   icon: <OpenArchitectureIcon />,
  //   title: "Open Architecture",
  //   descriptions:
  //     "Swiftly and easily integrate 3rd party tools and open-source frameworks with Actionabl's open architecture.",
  // },
];
export const different = [
  {
    id: 1,
    icon: <AutonomousIcon />,
    title: "Autonomous",
    descriptions:
      "Designed specifically for autonomous business processes, Actionabl enables straight-through processing with essential human oversight, ensuring a harmonious blend of automation and human intervention.",
    bgClass: "md:bg-insomnia bg-doctor",
  },
  {
    id: 2,
    icon: <UserCentricIcon />,
    title: "User-Centric",
    descriptions:
      "Actionabl delivers a cohesive, user-friendly business solution, emphasizing transparency, interactivity, and actionability to enhance user experience.",
    bgClass: "md:bg-doctor bg-insomnia",
  },
  {
    id: 3,
    icon: <InnovationPartnerIcon />,
    title: "Innovation Partner",
    descriptions:
      "At its core, Actionabl is driven by innovation, keeping you at the forefront of technological advancements and forging partnerships to meet your specific needs.",
    bgClass: "bg-doctor ",
  },
  {
    id: 4,
    icon: <OpenExtendableIcon />,
    title: "Open & Extendable",
    descriptions:
      "Featuring an open architecture and extendibility, including source code licensing options, Actionabl supports customization and expansion of its core capabilities to align with unique business needs.",
    bgClass: "bg-electricPink ",
  },
];
