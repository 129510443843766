import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import PrimaryButton from "./PrimaryButton";
import { MenuIcon } from "./Icons";
import NavLinks from "./NavLinks";
import { LogoIcon } from "./Icons";
import MobileSidebar from "./MobileSidebar";
import ContactUs from "./ContactUs";

const Header = () => {
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const handleopenContactUsModal = () => {
    setIsOpen(true);
  };

  const handlecloseContactUsModal = () => {
    setIsOpen(false);
  };

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  useEffect(() => {
    document.body.style.overflow = isSidebarVisible ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isSidebarVisible]);

  const handleDropdownItemClick = () => {
    setActiveDropdown(null); // Close the dropdown after clicking
  };

  const navItems = [
    { name: "Platform", path: "/" },
    {
      name: "About",
      path: "",
      dropdown: [
        { name: "Our Journey", path: "/journey" }, // dropdown item
        { name: "Our Vision", path: "/vision" },
        { name: "Our Mission", path: "/mission" },
        { name: "Leadership", path: "/leadership-team" },
      ],
    },
  ];

  let location = useLocation();
  const url_pathname = location.pathname;
  const ignorePaths = ["/"];
  //console.log("url_pathname",url_pathname);

  useEffect(() => {
    const heroBgElement = document.getElementById("header-bg");

    if (heroBgElement) {
      if (!ignorePaths.includes(url_pathname)) {
        heroBgElement.style.backgroundColor = "#0A0126";
        heroBgElement.style.backgroundImage = "none";
        heroBgElement.classList.remove("hero-bg");
      } else {
        heroBgElement.style.backgroundColor = "transparent";
        heroBgElement.style.backgroundImage = "none";
        heroBgElement.classList.add("hero-bg");
      }
    }
  }, [url_pathname]);

  return (
    <>
      <header className="relative w-full bg-gradient-to-r from-blue-900 to-blue-700 text-white" id="header-bg">
        <div className="relative z-40 3xl:h-[96px] md:h-[75px] h-14 flex items-center">
          <div className="flex justify-between items-center gap-4 main-container">
            <Link
              to={"/"}
              className="md:max-w-[215px] sm:max-w-[180px] max-w-[130px] w-full"
            >
              <LogoIcon />
            </Link>
            <div className="flex justify-between items-center gap-14">
  <ul className="hidden md:flex items-center gap-8 pr-[97px]">
    {navItems.map((item, index) => (
    <li
    key={index}
    className="relative group"
    onMouseEnter={() => item.dropdown && setActiveDropdown(index)}
    onMouseLeave={() => item.dropdown && setActiveDropdown(null)}
  >
    {item.name === "About" ? (
      <span
        className="cursor-pointer font-bold"
        onClick={(e) => e.preventDefault()} // Prevent any navigation
      >
        {item.name}
      </span>
    ) : (
      <NavLinks item={item} />
    )}
    {item.dropdown && activeDropdown === index && (
      <div
        id="dropdownNavbar"
        className="absolute z-10 divide-y divide-gray-100 rounded-lg shadow-lg bg-[#7B5BF0] w-44"
      >
        <ul
          className="py-2 text-sm text-white"
          aria-labelledby="dropdownLargeButton"
        >
          {item.dropdown.map((subItem, subIndex) => (
            <li key={subIndex}>
              <a
                href={subItem.path}
                className="block px-4 py-2 hover:bg-[#A18CFB] hover:text-white"
                onClick={handleDropdownItemClick}
              >
                {subItem.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    )}
  </li>
  
    ))}
  </ul>
</div>

            <div className="flex items-center gap-2">
            <PrimaryButton
  onClick={handleopenContactUsModal}
  className="max-w-[180px] w-full xs:block hidden bg-[#7B5BF0] hover:bg-[#5F47C7]"
>
  Contact Us
</PrimaryButton>

              <button onClick={toggleSidebar} className="block md:hidden text-white">
                <MenuIcon />
              </button>
            </div>
          </div>
        </div>
        <MobileSidebar
          isSidebarVisible={isSidebarVisible}
          toggleSidebar={toggleSidebar}
        />
      </header>

      <ContactUs
        isOpen={isOpen}
        handlecloseContactUsModal={handlecloseContactUsModal}
      />
    </>
  );
};

export default Header;
