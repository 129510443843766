import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import PrimaryButton from "./PrimaryButton";
import ContactUs from "./ContactUs";

const appUrl = process.env.REACT_APP_NEW_BASE_URL;

const LeadershipTeam = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  function handleopenContactUsModal() {
    setIsOpen(true);
  }

  function handlecloseContactUsModal() {
    setIsOpen(false);
  }

  return (
    <>
      <Helmet>
        <title>Leadership Team | Actionabl </title>
        <meta
          name="description"
          content=" Our leadership team, across the USA and India, brings technology, strategy, and skills to drive full autonomy and operational transformation."
        />
        <meta property="og:title" content=" Leadership Team | Actionabl " />
        <meta
          property="og:description"
          content="Our leadership team, across the USA and India, brings technology, strategy, and skills to drive full autonomy and operational transformation."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={`${appUrl}/og-img/Actionabl-Leadership-Og.jpg`}
        />
        <meta
          property="og:url"
          content={`${appUrl}/leadership-team`}
        />
        <meta
          name="twitter:image"
          content={`${appUrl}/og-img/Actionabl-Leadership-Og.jpg`}
        />
        <meta name="twitter:title" content=" Leadership Team | Actionabl " />
        <meta
          name="twitter:description"
          content=" Our leadership team, across the USA and India, brings technology, strategy, and skills to drive full autonomy and operational transformation."
        />
      </Helmet>

      <div className="bg-gray-100 min-h-screen font-satoshi mb-24">
        <div className="relative w-full h-48 sm:h-64 md:h-80 lg:h-96 xl:h-[400px] mb-8 bg-cover bg-center hero-bg flex justify-center items-center">
          <div className="shadow-lg rounded-lg p-8 relative z-10 text-center">
            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-bold text-white mb-6">
              Actionabl Leadership
            </h1>

            <PrimaryButton
              onClick={handleopenContactUsModal}
              className={"max-w-[164px] w-full mx-auto"}
            >
              Get Started
            </PrimaryButton>
          </div>
        </div>
        <div className="mt-2 pl-8">
          <Link to="/" className="text-blue-500 hover:underline">
            Home
          </Link>
          <span className="text-gray-600 mx-2"> &gt; </span>
          <span className="text-gray-800">Actionabl Leadership</span>
        </div>
        <div className="w-full px-4 sm:px-6 md:px-10 lg:px-12 py-6 md:py-8 lg:py-10 xl:py-12 text-gray-700 leading-relaxed mx-auto mb-8 shadow-lg rounded-lg">
          <div className="container mx-auto py-8 sm:py-12 md:py-14 lg:py-16 px-4 sm:px-6 md:px-8 lg:px-10 bg-white bg-opacity-50">
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-semibold text-gray-800 text-center mb-4">
              <span style={{ color: "#1E429F" }}>Leadership</span>
            </h2>

            <p className="mt-2 text-lg text-deep-night">
              Our team, based in the USA and India, combines deep expertise in
              Automation, Digital Transformation, Customer Success, and Business
              Growth. Our leadership team has a strong track record of
              innovation, scaling, and delivering solutions that have
              transformed operations for various organizations. We bring
              together a unique mix of technology, business strategy, and
              leadership skills to empower organizations, helping them achieve
              full autonomy and drive meaningful business growth.
            </p>
          </div>

          <div className="grid grid-cols-1 gap-6 sm:gap-8 md:gap-10 mt-6 sm:mt-8 md:mt-10 mb-6">
            <div className="flex flex-col lg:flex-row-reverse items-center text-center lg:text-left shadow-lg rounded-xl bg-white mx-2 sm:mx-4 md:mx-6 lg:mx-10 p-4 sm:p-6 md:p-8">
              <div className="rounded-lg overflow-hidden mb-4 lg:mb-0 lg:ml-8 flex items-center justify-center">
                <img
                   src={`${appUrl}/Developers/download (1).jpg`}
                  alt="Chiman Jagani"
                  className="object-cover w-full h-full opacity-80"
                />
              </div>
              <div className="lg:w-auto">
                <h3
                  className="text-lg sm:text-xl font-semibold"
                  style={{ color: "#171616" }}
                >
                  Chiman Jagani
                </h3>
                <p className="text-lg" style={{ color: "#1E429F" }}>
                  Co-Founder
                </p>
                <p className="text-gray-700 mt-2 text-sm sm:text-base">
                  As an IIT Bombay alumnus, Chiman brings over 35 years of
                  extensive IT experience as a Technology & Innovation Lead. He
                  has delivered large-scale enterprise solutions for clients
                  across North America, Europe, and Asia. A visionary and
                  technology innovator, Chiman possesses a profound
                  understanding of the past and a forward-thinking perspective
                  for the future, making him a valuable asset in driving growth
                  and innovation.
                </p>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row items-center text-center lg:text-left shadow-lg rounded-xl bg-white mx-2 sm:mx-4 md:mx-6 lg:mx-10 p-4 sm:p-6 md:p-8">
              <div className="rounded-lg overflow-hidden mb-4 lg:mb-0 lg:mr-8 flex items-center justify-center">
                <img
                  src={`${appUrl}/Developers/a2.jpg`}
                  alt="Chetan Panchani"
                  className="object-cover w-full h-full opacity-80"
                />
              </div>
              <div className="lg:w-auto">
                <h3
                  className="text-lg sm:text-xl font-semibold"
                  style={{ color: "#171616" }}
                >
                  Chetan Panchani
                </h3>
                <p className="text-lg" style={{ color: "#1E429F" }}>
                   Co-Founder & Chief Innovator
                </p>
                <p className="text-gray-700 mt-2 text-sm sm:text-base">
                  With a background at McKinsey & Company and over 20 years of
                  experience in digital transformation, business process
                  management, and process automation, Chetan is seasoned in
                  driving change. He has major transformations across North
                  America, empowering large organizations to transform and
                  innovate rapidly.
                </p>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row-reverse items-center text-center lg:text-left shadow-lg rounded-xl bg-white mx-2 sm:mx-4 md:mx-6 lg:mx-10 p-4 sm:p-6 md:p-8">
              <div className="rounded-lg overflow-hidden mb-4 lg:mb-0 lg:ml-8 flex items-center justify-center">
                <img
                  src={`${appUrl}/Developers/Hiren Panchani.jpg`}
                  alt="Hiren Panchani"
                  className="object-cover w-full h-full opacity-80"
                />
              </div>
              <div className="lg:w-auto">
                <h3
                  className="text-lg sm:text-xl font-semibold"
                  style={{ color: "#171616" }}
                >
                  Hiren Panchani
                </h3>
                <p className="text-lg" style={{ color: "#1E429F" }}>
                  Chief Business Officer
                </p>
                <p className="text-gray-700 mt-2 text-sm sm:text-base">
                  Hiren is a visionary leader with over 15 years of experience
                  in the digital transformation space, empowering businesses to
                  achieve full operational autonomy. As Chief Business Officer,
                  he will drive strategic planning, business operations,
                  financial management, culture, communications, partnerships,
                  collaboration, etc., and be responsible for Actionabl’s
                  business development & growth.
                </p>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row items-center text-center lg:text-left shadow-lg rounded-xl bg-white mx-2 sm:mx-4 md:mx-6 lg:mx-10 p-4 sm:p-6 md:p-8">
              <div className="rounded-lg overflow-hidden mb-4 lg:mb-0 lg:mr-8 flex items-center justify-center">
                <img
                  src={`${appUrl}/Developers/Rakesh Datta.jpg`}
                  alt="Rakesh Datta"
                  className="object-cover w-full h-full opacity-80"
                />
              </div>
              <div className="lg:w-auto">
                <h3
                  className="text-lg sm:text-xl font-semibold"
                  style={{ color: "#171616" }}
                >
                  Rakesh Datta
                </h3>
                <p className="text-lg" style={{ color: "#1E429F" }}>
                  Chief Sales Officer
                </p>
                <p className="text-gray-700 mt-2 text-sm sm:text-base">
                  Rakesh Datta is a true sales leader with over 20 years of
                  experience in driving business growth and strategic
                  initiatives related to sales. He has worked with industry
                  leaders such as Oracle, Jiffi.ai, etc. As Chief Sales Officer,
                  Rakesh will execute strategic sales initiatives and lead the
                  sales team, driving revenue growth.
                </p>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row-reverse items-center text-center lg:text-left shadow-lg rounded-xl bg-white mx-2 sm:mx-4 md:mx-6 lg:mx-10 p-4 sm:p-6 md:p-8">
              <div className="rounded-lg overflow-hidden mb-4 lg:mb-0 lg:ml-8 flex items-center justify-center">
                <img
                  src="/Developers/Anurag-Saxena.jpg"
                  alt="Anurag Saxena"
                  className="object-cover w-full h-full opacity-80"
                />
              </div>
              <div className="lg:w-auto">
                <h3
                  className="text-lg sm:text-xl font-semibold"
                  style={{ color: "#171616" }}
                >
                  Anurag Saxena
                </h3>
                <p className="text-lg" style={{ color: "#1E429F" }}>
                  Chief Partnership Officer
                </p>
                <p className="text-gray-700 mt-2 text-sm sm:text-base">
                  Anurag brings over 18 years of experience in business
                  development, strategic partnerships, and sales. He has a
                  proven track record in building strong partnerships that drive
                  growth and deliver significant value for organizations. As
                  Chief Partnership Officer, Anurag will play a critical role in
                  identifying and nurturing strategic partnerships.
                </p>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row items-center text-center lg:text-left shadow-lg rounded-xl bg-white mx-2 sm:mx-4 md:mx-6 lg:mx-10 p-4 sm:p-6 md:p-8">
              <div className="rounded-lg overflow-hidden mb-4 lg:mb-0 lg:mr-8 flex items-center justify-center">
                <img
                  src={`${appUrl}/Developers/a3.jpg`}
                  alt="Sarvesh Renghe"
                  className="object-cover w-full h-full opacity-80 "
                />
              </div>
              <div className="lg:w-auto">
                <h3
                  className="text-lg sm:text-xl font-semibold"
                  style={{ color: "#171616" }}
                >
                  Sarvesh Renghe
                </h3>
                <p className="text-lg" style={{ color: "#1E429F" }}>
                  Chief Customer Success Officer
                </p>
                <p className="text-gray-700 mt-2 text-sm sm:text-base">
                  Sarvesh Renghe is a highly experienced professional with over
                  30 years in software development. As Chief Customer Success
                  Officer, he leads the customer success function, ensuring
                  clients achieve their goals and realize the full value of the
                  company’s solutions. He excels in driving customer engagement,
                  managing implementations, and building trusted partnerships.
                </p>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row-reverse items-center text-center lg:text-left shadow-lg rounded-xl bg-white mx-2 sm:mx-4 md:mx-6 lg:mx-10 p-4 sm:p-6 md:p-8">
              <div className="rounded-lg overflow-hidden mb-4 lg:mb-0 lg:ml-8 flex items-center justify-center">
                <img
                  src="/Developers/VipinPanchamia.jpg"
                  alt="Vipin Panchamia - Developer"
                  className="object-cover w-full h-full opacity-80"
                />
              </div>
              <div className="lg:w-auto">
                <h3
                  className="text-lg sm:text-xl font-semibold"
                  style={{ color: "#171616" }}
                >
                  Vipin Panchamia
                </h3>
                <p className="text-lg" style={{ color: "#1E429F" }}>
                  Financial Head
                </p>
                <p className="text-gray-700 mt-2 text-sm sm:text-base">
                  Vipin is a seasoned finance and taxation expert with over
                  three decades in the field. As a Finance Head, he looks after
                  strategic planning and financial management, ensuring
                  efficiency and profitability. His strategic insight and
                  analysis are key in navigating today's financial challenges.
                </p>
              </div>
            </div>
          </div>
        </div>
        <ContactUs
          isOpen={isOpen}
          handlecloseContactUsModal={handlecloseContactUsModal}
        />
      </div>
    </>
  );
};

export default LeadershipTeam;
