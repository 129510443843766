import React, { useState } from "react";
import { Link } from "react-router-dom";
import { LoadingIcon, LogoIcon } from "./Icons";
import DiscoverTheFuture from "../homepage/DiscoverTheFuture";
import { footerItems } from "./Helper";

const SocialLinks = ({ links }) => (
  <div className="flex space-x-4">
    {links.map((link) => (
      <Link
        target="_blank"
        key={link.name}
        to={link.href}
        className="w-10 h-10 flex items-center justify-center bg-candy hover:shadow-md hover:shadow-candy rounded-full duration-200 ease-in-out transition-all"
      >
        {link.icon}
      </Link>
    ))}
  </div>
);

const FooterLinksSection = ({ title, items }) => (
  <div className="w-full max-w-[218px]">
    {/* <h3 className="text-xl text-white font-bold leading-[150%] mb-4">
      {title}
    </h3> */}
  </div>
);

const Footer = () => {
  const [email, setEmail] = useState("");
  const [buttonState, setButtonState] = useState("initial");

  const handleSubmit = (event) => {
    event.preventDefault();
    if (buttonState === "initial") {
      setButtonState("loading");

      setTimeout(() => {
        setButtonState("subscribed");
        setEmail("");
      }, 2000);
    }
  };

  return (
    <footer className="footer-bg relative w-full">
      <DiscoverTheFuture />
      <div className="main-container pb-10">
        <div className="flex justify-between gap-14">
          <div className="w-full max-w-[306px]">
            <Link
              to="/"
              className="w-full max-w-[214px] flex"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              <LogoIcon />
            </Link>
            <p className="desktop-only text-sm md:text-base lg:text-lg leading-[110%] tracking-[-0.18px] text-white my-5 lg:whitespace-nowrap">
              Realize the Vision of Autonomous Enterprise
            </p>


            <SocialLinks links={footerItems.socialLinks} />
          </div>

          <div className="flex flex-wrap justify-between w-full gap-10">
            <FooterLinksSection title="Company" items={footerItems.company} />
            <FooterLinksSection title="Links" items={footerItems.links} />

            <div className="flex items-center justify-end w-full">
              <div className="flex items-center justify-center max-w-[306px]">
                <Link
                  to="/compliance-security-quality"
                  aria-label="ISO Certification"
                >
                  <img
                    src="/Certified27001/Certified27001.png"
                    alt="ISO 27001 Certified"
                    className="h-28 w-32 Certified27001"
                    style={{
                      "@media (min-width: 768px)": {
                        marginTop: "-50px",
                      },
                    }}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;