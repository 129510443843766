import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import PrimaryButton from "./PrimaryButton";
import ContactUs from "./ContactUs";

const appUrl = process.env.REACT_APP_NEW_BASE_URL || "";

const OurMission = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  function handleOpenContactUsModal() {
    setIsOpen(true);
  }

  function handleCloseContactUsModal() {
    setIsOpen(false);
  }

  return (
    <>
      <Helmet>
        <title>Our Company Mission | Actionabl</title>
        <meta
          name="description"
          content="Our mission is to empower businesses with innovation, agility, resilience, and transparency for true operational autonomy."
        />
        <meta property="og:title" content="Our Company Mission | Actionabl" />
        <meta
          property="og:description"
          content="Our mission is to empower businesses with innovation, agility, resilience, and transparency for true operational autonomy."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={`${appUrl}/og-img/OurMissionOg.jpg`}
        />
        <meta
          name="twitter:image"
          content={`${appUrl}/og-img/OurMissionOg.jpg`}
        />
        <meta name="twitter:title" content="Our Company Mission | Actionabl" />
        <meta
          name="twitter:description"
          content="Our mission is to empower businesses with innovation, agility, resilience, and transparency for true operational autonomy."
        />
      </Helmet>

      <div className="relative mb-24 bg-gradient-to-br bg-[#f1f5f9] overflow-hidden">
        {/* Header Section */}
        <div className="relative w-full h-48 sm:h-64 md:h-80 lg:h-96 xl:h-[400px] mb-8 bg-cover bg-center hero-bg flex justify-center items-center">
          <div className="shadow-lg rounded-lg p-8 relative z-10 text-center">
            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-bold text-white mb-6">
              Actionabl Mission
            </h1>

            <PrimaryButton
              onClick={handleOpenContactUsModal}
              className={"max-w-[164px] w-full mx-auto"}
            >
              Get Started
            </PrimaryButton>
          </div>
        </div>

        <div className="mt-4 pl-8 relative z-10">
          <Link
            to="/"
            className="text-blue-500 hover:underline font-medium transition-colors"
          >
            Home
          </Link>
          <span className="text-gray-600 mx-2"> &gt; </span>
          <span className="text-gray-800">Actionabl Mission</span>
        </div>

        <section className="pt-[20px]">
          <div className="container mx-auto py-8 sm:py-12 md:py-14 lg:py-16 px-4 sm:px-6 md:px-8 lg:px-10 bg-white bg-opacity-50 mt-10">
            <p className="mt-2 text-lg text-deep-night">
              At Actionabl, our mission is to empower businesses by creating
              processes rooted in innovation, agility, resilience, and
              transparency. In a rapidly changing technological environment,
              where organizations aim for operational autonomy, we are committed
              to being the trusted partner that not only simplifies but also
              enhances their journey toward true operational autonomy.
            </p>
            <p className="mt-2 text-lg text-deep-night">
              We believe in transforming Business Operations creating processes
              that don’t just automate tasks but also orchestrate end-to-end
              intelligently. It serves as a platform of platforms, unifying
              disparate systems to unlock seamless collaboration across the
              enterprise. This approach empowers organizations to optimize
              operations, reduce complexity, and focus on innovation and growth.
            </p>
          </div>

          <div className="grid grid-cols-1 gap-6 mt-10 px-4 pb-20">
            {[
              {
                title: "Innovation",
                content:
                  "Innovation is at the core of what we do. By leveraging the latest advancements in artificial intelligence, machine learning, and agentic automation, we develop processes (solutions) that push the boundaries of what businesses can achieve. Our platform is designed to adapt and evolve alongside industry needs, ensuring our clients remain ahead of the curve. Through constant innovation, we enable organizations to harness the full potential of digital transformation, allowing them to stay agile and responsive in a competitive marketplace.",
                iconPath: "/icons/Innovation.jpg",
              },
              {
                title: "Agility",
                content:
                  "Agility is essential in today’s dynamic market. We strive to create processes and strategies that enable organizations to respond to changes quickly and effectively, fostering adaptability without compromising operational efficiency. Designed to empower businesses to navigate disruptions and seize new opportunities, ensuring they remain resilient and competitive. With a focus on flexibility and rapid deployment, we provide organizations with the platform that allows to remain agile and responsive in the face of evolving challenges.",
                iconPath: "/icons/Agility.jpg",
              },
              {
                title: "Resilience",
                content:
                  "Resilience underpins our commitment to helping businesses thrive in the face of uncertainty. By building robust, scalable processes (solutions), we ensure continuity and stability, even in challenging circumstances. By focusing on creating resilient systems, we equip our clients with the ability to withstand disruptions and maintain operations smoothly. This resilience enables businesses to focus on their growth and strategic goals without being derailed by unexpected challenges.",
                iconPath: "/icons/Resilience.jpg",
              },
              {
                title: "Transparency",
                content:
                  "Transparency is integral to our mission. We promote clear communication, actionable insights, and ethical practices, ensuring our clients have the trust and confidence needed to succeed. By fostering transparency in all our interactions, we build strong relationships based on mutual trust and understanding. Our clients know that with Actionabl, they can confidently navigate their journey to operational autonomy, equipped with the insights and tools they need to succeed. Actionabl is more than a Platform technology provider; we are a catalyst for meaningful change. Together, we empower businesses to achieve unparalleled autonomy, unlocking their potential to innovate, lead, and redefine their industries. With our mission as our guide, we are shaping the future of autonomous enterprises.",
                iconPath: "/icons/Transparency.jpg",
              },
            ].map(({ title, content, iconPath }, index) => (
              <div
                key={index}
                className="flex flex-col lg:flex-row items-center lg:items-start shadow-lg rounded-xl bg-white mx-4 p-6"
              >
                <img
                  src={iconPath}
                  alt={title}
                  className="flex flex-col items-center lg:items-start mb-4 lg:mb-0 lg:mr-4"
                />
                <div className="text-center lg:text-left">
                  <h3 className="2xl:text-[32px] 1xl:text-3xl xl:text-2xl lg:text-xl text-lg font-bold text-secondary text-gray-900 mb-2">
                    {title}
                  </h3>
                  <p className="font-normal 3xl:max-w-[376px] mt-2 text-gray-600">
                    {content}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </section>

        <ContactUs
          isOpen={isOpen}
          handlecloseContactUsModal={handleCloseContactUsModal}
        />
      </div>
    </>
  );
};

export default OurMission;
