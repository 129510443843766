import React from "react";
import HelmetMeta from "../HelmetMeta";
import Hero from "../homepage/Hero";
import AutonomousEnterprise from "../homepage/AutonomousEnterprise";
import WhatMakes from "../homepage/WhatMakes";
import CoreCapabilities from "../homepage/CoreCapabilities";
import KeyBenefits from "../homepage/KeyBenefits";
import Ecosystem from "../homepage/ActionablEcosystem";
import ScrollTop from "../common/ScrollTop";

const Homepage = () => {
  const apiUrl = process.env.REACT_APP_NEW_BASE_URL;

  return (
    <>
      <HelmetMeta
        title="Empowering Autonomous Enterprise | Business Orchestration | Actionabl"
        description="Actionabl is an end-to-end Business Orchestration platform designed to make your enterprise operations fully autonomous."
        image={`${apiUrl}/og-img/ActionablHompage.jpg`}
        twitterCard="summary_large_image"
        type="website"
        url={`${apiUrl}/`}
      />

      <Hero />
      <AutonomousEnterprise />
      <WhatMakes />
      <CoreCapabilities />
      <KeyBenefits />
      <Ecosystem />
      <ScrollTop />
    </>
  );
};

export default Homepage;
