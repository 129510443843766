import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ContactUs from "./ContactUs";
import PrimaryButton from "./PrimaryButton";

const appUrl = process.env.REACT_APP_NEW_BASE_URL || "https://actionabl.ai/";
const baseUrl = process.env.REACT_APP_NEW_BASE_URL;

const Journey = () => {
  const [openDetails, setOpenDetails] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDetails = (index) => {
    setOpenDetails(openDetails === index ? null : index);
  };

  const handleOpenContactUsModal = () => {
    setIsOpen(true);
  };

  const handleCloseContactUsModal = () => {
    setIsOpen(false);
  };

  const timelineData = [
    {
      year: "2021",
      title: "The Beginning",
      description: (
        <>
          <span className="text-[#7b5bf0] font-bold">
            Founded by the Visionary Technologist:
          </span>{" "}
          Actionabl was established by Chetan Panchani and Chiman Jagani with
          the vision of enabling autonomous enterprise operations.
        </>
      ),
      text: (
        <>
          <span className="text-[#7b5bf0] font-bold">Mission:</span> Empowering
          businesses to achieve operational autonomy with innovation, agility,
          resilience, and transparency.
        </>
      ),
    },
    {
      year: "2022",
      title: "Building Foundations & Launch and Early Adoption",
      description: (
        <div className="text-white">
          <div>
            <span className="text-[#7b5bf0] font-bold">
              Platform Development:
            </span>{" "}
            Focused on developing the core architecture of the platform,
            emphasizing security &nbsp;&nbsp;&nbsp;&nbsp; and seamless
            integration capabilities with technologies into a single
            orchestration framework.
          </div>
        </div>
      ),
      text: (
        <div className="text-white mt-2">
          <div>
            <span className="text-[#7b5bf0] font-bold">Product Launch:</span>{" "}
            Released the Actionabl platform with advanced BPM orchestration
            features.
          </div>
          <div>
            <span className="text-[#7b5bf0] font-bold">Key Clients:</span>{" "}
            Secured early adopters across industries such as Banking & Finance.
          </div>
          <div>
            <span className="text-[#7b5bf0] font-bold">
              ISO 27001 Certification:
            </span>{" "}
            Strengthened the platform’s credibility with global security
            standards.
          </div>
        </div>
      ),
    },

    {
      year: "2023",
      title: "Team Expansion & Driving Innovation",
      description: (
        <div className="text-white">
          <div>
            <span className="text-[#7b5bf0] font-bold">Team Expansion:</span>{" "}
            Grew the team across the USA and India to support scaling and
            customer success.
          </div>
          <div>
            <span className="text-[#7b5bf0] font-bold">
              The Platform of Platforms:
            </span>{" "}
            Established Actionabl as the go-to Business Orchestration Platform,
            integrating &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;tools and systems for
            holistic enterprise management.
          </div>
          <div>
            <span className="text-[#7b5bf0] font-bold">Enhanced Features:</span>{" "}
            Introduced advanced AI-driven automation, process insights analytics
            for decision-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;making, and real-time
            monitoring.
          </div>
        </div>
      ),
    },
    {
      year: "2024",
      title: "Partnership",
      description: (
        <span>
          Announced a ground-breaking partnership with{" "}
          <a
            href="https://www.featsystems.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#ff825c] hover:underline"
          >
            Feat Systems
          </a>
          .
        </span>
      ),
    },
    {
      year: "2025",
      title: "Shaping the Future",
      description: (
        <>
          <p>Realized significant advancements</p>
        </>
      ),
      moreText: [
        "Enhanced Customer Interactivity with CRM Features",
        "Agentic AI",
        "AI Assistant",
        "Advanced Mobile Capabilities",
        "Intelligent Process Recommendations",
        "AI-Powered Compliance Monitoring",
      ],
    },
  ];

  return (
    <>
      <Helmet>
        <title>Our Journey to Enable Autonomous Operations | Actionabl</title>
        <meta
          name="description"
          content="Actionabl journey started as more than just a vision—it was a shared mission to redefine and empower organizations to achieve full autonomy."
        />
        <meta
          property="og:title"
          content="Our Journey to Enable Autonomous Operations | Actionabl"
        />
        <meta
          property="og:description"
          content="Actionabl journey started as more than just a vision—it was a shared mission to redefine and empower organizations to achieve full autonomy."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={`${baseUrl}/og-img/OurJourneyOg.jpg`}
        />
        <meta
          name="twitter:image"
          content={`${baseUrl}/og-img/OurJourneyOg.jpg`}
        />
        <meta
          name="twitter:title"
          content="Our Journey to Enable Autonomous Operations | Actionabl"
        />
        <meta
          name="twitter:description"
          content="Actionabl journey started as more than just a vision—it was a shared mission to redefine and empower organizations to achieve full autonomy."
        />
      </Helmet>

      <div className="bg-gray-100 min-h-screen font-satoshi mb-24">
        <div className="relative w-full h-48 sm:h-64 md:h-80 lg:h-96 xl:h-[400px] mb-8 bg-cover bg-center hero-bg flex justify-center items-center">
          <div className="shadow-lg rounded-lg p-8 relative z-10 text-center">
            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-bold text-white mb-6">
              Actionabl Journey
            </h1>
            <PrimaryButton
              onClick={handleOpenContactUsModal}
              className={"max-w-[164px] w-full mx-auto"}
            >
              Get Started
            </PrimaryButton>
          </div>
        </div>
        <div className="mt-2 pl-8">
          <Link to="/" className="text-blue-500 hover:underline">
            Home
          </Link>
          <span className="text-gray-600 mx-2">&gt;</span>
          <span className="text-gray-800">Actionabl Journey</span>
        </div>
        <div className="w-full px-4 sm:px-6 md:px-10 lg:px-12 py-6 md:py-8 lg:py-10 xl:py-12 text-gray-700 leading-relaxed mx-auto mb-8 shadow-lg rounded-lg">
          <div className="container mx-auto py-8 sm:py-12 md:py-14 lg:py-16 px-4 sm:px-6 md:px-8 lg:px-10 bg-white bg-opacity-50">
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-semibold text-gray-800 text-center mb-4">
              <span style={{ color: "#1E429F" }}>
                A Vision to Enable Autonomous Enterprise Operations
              </span>
            </h2>
            <p className="mt-2 text-lg text-deep-night">
              The Actionabl Platform emerged as the answer to tomorrow's most
              pressing challenges. This was product innovation in its truest
              form—a futuristic platform of Business Automation with the vision
              of an Autonomous Enterprise at its core. Our guiding principle,
              "Shaping the Future of Autonomous Enterprise," has never been just
              a slogan; it’s the driving force behind a platform that redefines
              the way businesses operate and scale to achieve full autonomy.
              Every feature of Actionabl is designed with the future in mind
              empowering businesses to stay ahead of the curve, foster impactful
              customer experiences, and unlock new levels of efficiency,
              resiliency, and agility.
            </p>
          </div>
        </div>
        <div className="timeline relative mx-auto max-w-4xl px-4 sm:px-6 lg:px-8 mt-20 pb-12">
          <div className="absolute top-0 transform -translate-x-1/2 bg-gradient-to-b from-gray-300 to-gray-500 w-1 animate__animated animate__fadeIn animate__delay-2s h-full"></div>
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-semibold text-gray-800 text-center mb-4">
            <span style={{ color: "#1E429F" }}>Journey</span>
          </h2>
          {timelineData.map((item, index) => (
            <div key={index} className="mb-8">
              <div
                onClick={() => toggleDetails(index)}
                className={`container-timeline relative flex items-center px-6 py-4 rounded-md shadow-md cursor-pointer transition-all duration-300 ${
                  openDetails === index
                    ? "bg-[#0a0126] text-[#7B5BF0]"
                    : "bg-transparent text-black-700"
                }`}
              >
                <div className="relative mx-5 sm:mx-3 lg:mx-0 lg:absolute lg:left-[-60px] transform lg:-translate-x-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:left-[-50px] lg:translate-x-0 font-semibold">
                  {item.year}
                </div>
                <div className="content-timeline left-[60px]">
                  <h3 className="text-xl font-semibold">{item.title}</h3>
                  {openDetails === index && (
                    <div className="mt-2">
                      <p className="text-white">{item.description}</p>
                      <p className="mt-4 text-white">{item.text}</p>
                      {item.moreText && (
                        <ul className="list-disc list-inside mt-4 text-white">
                          {item.moreText.map((text, idx) => (
                            <li key={idx}>{text}</li>
                          ))}
                        </ul>
                      )}

                      {item.title === "Shaping the Future" && (
                        <div className="mt-4">
                          <p className="text-white">
                            <span className="text-[#7b5bf0] font-bold">
                              SOC 2 & HIPAA Certification:{" "}
                            </span>
                            Proud to uphold the highest standards of data
                            security and compliance.
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <span
                  className={`absolute top-1/2 left-0 transform -translate-y-1/2 w-4 h-4 rounded-full -ml-2 bg-[#7B5BF0]`}
                ></span>
              </div>
            </div>
          ))}
        </div>

        <ContactUs
          isOpen={isOpen}
          handlecloseContactUsModal={handleCloseContactUsModal}
        />
      </div>
    </>
  );
};

export default Journey;
