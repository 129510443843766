import KeyBenefitsCard from "./KeyBenefitsCard";
import { keyBenefits } from "../common/Icons/Helper";
const KeyBenefits = () => {
  return (
    <section className="bg-cloud 3xl:py-[120px] xl:py-20 lg:py-16 py-14">
      <div className="main-container">
        <h3 className="heading text-white text-center">Key Benefits</h3>
        <div className="flex flex-wrap justify-center lg:gap-8 gap-4 lg:mt-[60px] md:mt-14 mt-10">
          {keyBenefits.map((benefitsItem, index) => (
            <KeyBenefitsCard key={index} benefitsItem={benefitsItem} /> 
          ))}
        </div>
      </div>
    </section>
  );
};

export default KeyBenefits;
