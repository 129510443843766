import React, { useState, useRef, useEffect } from "react";
import { HeroFutureIcon } from "../common/Icons/Icons";
import SecondaryButton from "../common/Form/SecondaryButton";

const Hero = () => {
  const [showVideo, setShowVideo] = useState(false);
  const [loadVideo, setLoadVideo] = useState(false);
  const [thumbnailSize, setThumbnailSize] = useState({ width: 0, height: 0 });
  const thumbnailRef = useRef(null);

  useEffect(() => {
    const updateThumbnailSize = () => {
      if (thumbnailRef.current) {
        const { offsetWidth, offsetHeight } = thumbnailRef.current;
        if (offsetWidth && offsetHeight) {
          setThumbnailSize({ width: offsetWidth, height: offsetHeight });
        }
      }
    };

    updateThumbnailSize();
    window.addEventListener("resize", updateThumbnailSize);
    return () => window.removeEventListener("resize", updateThumbnailSize);
  }, [showVideo]);

  const handleOpenVideo = () => {
    setShowVideo(true);
    setTimeout(() => setLoadVideo(true), 100); // Slight delay for smooth rendering
  };

  const handleCloseVideo = () => {
    setShowVideo(false);
    setLoadVideo(false);
  };

  return (
    <div className="hero-bg mt-[-100px]">
      <section
        style={{ paddingTop: "180px" }}
        className="top-bar-container Shaping flex flex-col lg:flex-row items-center lg:items-start justify-center w-full mx-auto sm:py-10 md:py-16 lg:py-20 py-8 main-container gap-6 lg:gap-10 p-6 sm:px-10 md:px-[80px] lg:px-[100px] mb-10 sm:mt-[-30px] md:mt-[-50px] pt-[180px]"
      >
        <div className="flex-1 flex flex-col items-start justify-center gap-4">
          <h1 className="hero-heading text-white text-center lg:text-left">
            Shaping the
            <span className="relative mx-3">
              <span className="z-[20] relative">Future</span>
              <HeroFutureIcon className="future-Icon absolute -right-10 -top-[50px] z-[10] pointer-events-none w-[140%]" />
            </span>
            of Autonomous Enterprise
          </h1>

          <p className="hero-sub-heading md:mt-6 mt-3 lg:mb-[98px] sm:mb-16 mb-8 text-center lg:text-left">
            Elevate your Business to New Heights of Efficiency and Innovation.
          </p>

          <div
            className="btn mt-20 relative w-full text-center lg:mt-[10px]"
            style={{ transform: "translateY(-6rem)" }}
          >
            <SecondaryButton
              className="btn max-w-[130px] flex justify-center items-center font-bold w-auto py-2.5 px-5 text-base font-medium rounded-full border border-gray-600 hover:bg-[#7b5bf0] hover:text-gray focus:outline-none transition-all lg:absolute mx-auto block w-[100px]"
              style={{ top: "-33px" }}
            >
              Schedule
            </SecondaryButton>
          </div>
        </div>

        <div className="shaping-Img flex-1 flex items-center justify-center relative">
          {showVideo && (
            <div
              className="absolute inset-0 flex items-center justify-center z-50 bg-black"
              style={{
                width: thumbnailSize.width,
                height: thumbnailSize.height,
              }}
            >
              {loadVideo && (
                <iframe
                  key={showVideo} // Forces re-rendering
                  src="https://www.youtube.com/embed/u8-hYTw0Ocw?autoplay=1&t=1s"
                  title="YouTube video player"
                  className="w-full h-full"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              )}
              <button
                onClick={handleCloseVideo}
                className="absolute top-2 right-2 bg-gray-700 text-white text-sm px-2 py-1 rounded shadow-md hover:bg-gray-600"
              >
                Close
              </button>
            </div>
          )}

          <img
            ref={thumbnailRef}
            src="/videoImg/Thumbnail.jpg"
            alt="Illustration"
            className="w-full max-w-[300px] lg:max-w-none object-contain cursor-pointer"
            onClick={handleOpenVideo}
          />

          <div className="absolute inset-0 flex items-center justify-center">
            {!showVideo && (
              <button
                className="Watch-Now w-16 h-16 bg-purple-500 rounded-full ring-4 ring-white flex items-center justify-center hover:bg-purple-400 transition-transform transform hover:scale-110 shadow-lg 
                sm:w-14 sm:h-14 sm:ring-2 
                md:w-16 md:h-16 md:ring-4"
                onClick={handleOpenVideo}
              >
                <span className="sr-only">Watch the video</span>
                <svg
                  className="w-6 h-6"
                  viewBox="0 0 16 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 7.26795C16.3333 8.03775 16.3333 9.96225 15 10.7321L3 17.6603C1.66667 18.4301 1.01267e-06 17.4678 1.07997e-06 15.9282L1.68565e-06 2.0718C1.75295e-06 0.532196 1.66667 -0.430054 3 0.339746L15 7.26795Z"
                    fill="white"
                  />
                </svg>
              </button>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hero;
