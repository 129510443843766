
const SecondaryButton = ({ className, children, onClick }) => {
  return (
    <a href="https://calendly.com/hiren-panchani-actionabl" target="_blank" rel="noopener noreferrer">
    <button
      onClick={onClick}
      className={`secondary-button overflow-hidden group w-full  ${className}`}
    >
      {children}
    </button>
  </a>
  );
};

export default SecondaryButton;



