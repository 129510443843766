import { capabilities } from "../common/Icons/Helper";
import CoreCapabilitiesCard from "./CoreCapabilitiesCard";

const CoreCapabilities = () => {
  const combinedGroup = capabilities.slice(0, 7); 
  return (
    <section className="main-container 3xl:py-[120px] xl:py-20 lg:py-16 py-14">
      <h3 className="heading text-center text-black">Core Capabilities</h3>
      <div className="3xl:space-y-8 xl:space-y-6 space-y-4 lg:mt-[60px] md:mt-14 sm:mt-10 mt-6">
      <div className="flex flex-wrap justify-center gap-3 bg-doctor rounded-[32px] py-3 1xl:py-6 3xl:py-10">
  {combinedGroup.slice(0,5).map((benefitsItem, index) => (
    <CoreCapabilitiesCard key={index} benefitsItem={benefitsItem} />
  ))}
</div>
      </div>
    </section>
  );
};
export default CoreCapabilities;