import { Link } from "react-router-dom";
import PrimaryButton from "./PrimaryButton";
import { CrossIcon, LogoIcon } from "./Icons";
import { useState } from "react";
import ContactUs from "./ContactUs";
import { FaInfoCircle, FaTh } from "react-icons/fa"; // Importing icons

const MobileSidebar = ({ isSidebarVisible, toggleSidebar }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showAboutSubmenu, setShowAboutSubmenu] = useState(false);

  const navItems = [
    { name: "Platform", path: "/", icon: <FaTh /> }, // Added icon for Platform
    {
      name: "About",
      children: [
        { name: "Our Journey", path: "/journey" },
        { name: "Our Vision", path: "/vision" },
        { name: "Our Mission", path: "/mission" },
        { name: "Leadership", path: "/leadership-team" },
      ],
      icon: <FaInfoCircle />, // Added icon for About
    },
  ];

  function handleOpenContactUsModal() {
    setIsOpen(true);
  }

  function handleCloseContactUsModal() {
    setIsOpen(false);
  }

  function toggleSubmenu() {
    setShowAboutSubmenu((prev) => !prev);
  }

  return (
    <>
      {isSidebarVisible && (
        <div
          className="fixed inset-0 bg-black/70 z-40"
          onClick={toggleSidebar}
          aria-label="Close Sidebar"
        ></div>
      )}
      <aside
        className={`bg-[url('./assets/images/webp/hero-bg.webp')] object-cover bg-no-repeat w-full max-w-[276px] fixed lg:hidden flex flex-col justify-start items-start transition-transform duration-300 ease-in-out transform z-50 bottom-0 h-screen py-4 right-0 ${
          isSidebarVisible ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="h-full grow overflow-y-auto custom-scrollbar px-4 w-full">
          <div className="flex items-center justify-between gap-5">
            <Link
              className="w-full sm:mx-auto max-w-[130px] sm:max-w-fit"
              to={"/"}
            >
              <LogoIcon />
            </Link>
            <button
              className="border-primary border p-1.5 rounded-full hover:rotate-180 duration-200 ease-in-out transition-all"
              onClick={toggleSidebar}
            >
              <CrossIcon />
            </button>
          </div>
          <div className="w-full mt-4">
            <ul className="flex flex-col gap-2">
              {navItems.map((item, index) => (
                <li
                  key={index}
                  className="transition-all duration-300 hover:border-gray-400"
                >
                  {item.children ? (
                    <>
                      <button
                        className="w-full font-bold text-left px-6 py-3 rounded-md bg-transparent transition-all duration-300 hover:scale-105 text-gray-200 flex items-center gap-2"
                        onClick={toggleSubmenu}
                      >
                        {item.icon} {item.name}
                      </button>
                      {showAboutSubmenu && (
                        <ul className="ml-6 mt-2 border-l-4 border-gray-600 pl-4 space-y-2">
                          {item.children.map((subItem, subIndex) => (
                            <li
                              key={subIndex}
                              className="border border-gray-700 rounded-md transition-all duration-300 hover:border-gray-400 bg-[#7B5BF0]"
                            >
                              <Link
                                to={subItem.path}
                                onClick={() => {
                                  toggleSidebar();
                                  setShowAboutSubmenu(false);
                                }}
                                className="block  px-2 py-1 text-gray-100 hover:text-white transition-all duration-300 hover:scale-105"
                              >
                                {subItem.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </>
                  ) : (
                    <Link
                      to={item.path}
                      className="block text-gray-100 px-6 font-bold py-3 rounded-md w-full text-left bg-transparent border-gray-100 hover:border-gray-400 text-white hover:text-white transition-all duration-300 hover:scale-105 flex items-center gap-2"

                      onClick={toggleSidebar}
                    >
                      {item.icon} {item.name}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="px-6 w-full">
          <PrimaryButton onClick={handleOpenContactUsModal} className="w-full">
            Contact Us
          </PrimaryButton>
        </div>
      </aside>
      <ContactUs
        isOpen={isOpen}
        handlecloseContactUsModal={handleCloseContactUsModal}
      />
    </>
  );
};

export default MobileSidebar;
